import groupBy from 'lodash/groupBy';
import omitBy from 'lodash/omitBy';
import difference from 'lodash/difference';

import { isEmptyOrNull } from './util';
import { formatSmartDates, calculateNewAge } from './date-time';

const CHUNK_SIZE = 1000;
const ENA_CHUNK_SIZE = 75; // Accounting for up to * 20 per row

export const handleAutoPopulation = (instance, itemIndex, curRow, increment = false) => {
  const itemData = instance.getDataAtCell(curRow, itemIndex);

  if (itemData != null) {
    if (increment) {
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(itemData)) {
        return [curRow + 1, itemIndex, Number(itemData) + 1];
      }
      return null;
    }
    return [curRow + 1, itemIndex, itemData];
  }
  return null;
};

export const applyAutoPopulationToMortalityCell = (instance, autoPopList, headerKeys, currentRow) => {
  const changes = [];
  let lastPopulatedKey = null;
  [
    {
      name: 'cluster',
      increment: false,
      keyName: 'cluster'
    },
    {
      name: 'survDate',
      increment: false,
      keyName: 'survdate'
    },
    {
      name: 'team',
      increment: false,
      keyName: 'team'
    },
    {
      name: 'hh',
      increment: true,
      keyName: 'hh'
    }
  ].forEach(item => {
    if (autoPopList.includes(item.name)) {
      const itemindex = headerKeys.indexOf(item.keyName);
      changes.push(
        handleAutoPopulation(instance, itemindex, currentRow, item.increment)
      );
      lastPopulatedKey = item.keyName;
    }
  });
  const filteredChanges = changes.filter(change => change !== null);
  instance.batchRender(() => {
    if (filteredChanges.length > 0) {
      instance.setDataAtCell(filteredChanges, 'prefill');
    }
  });
  return lastPopulatedKey;
};

export const applyAutoPopulationToCell = (instance, autoPopList, headerKeys, currentRow) => {
  const changes = [];
  instance.batchRender(() => {
    [
      {
        name: 'cluster',
        increment: false,
        keyName: 'cluster'
      },
      {
        name: 'survDate',
        increment: false,
        keyName: 'survdate'
      },
      {
        name: 'team',
        increment: false,
        keyName: 'team'
      },
      {
        name: 'hh',
        increment: true,
        keyName: 'hh'
      },
      {
        name: 'id',
        increment: true,
        keyName: 'child_id'
      }
    ].forEach(item => {
      if (autoPopList.includes(item.name)) {
        const itemindex = headerKeys.indexOf(item.keyName);
        changes.push(
          handleAutoPopulation(instance, itemindex, currentRow, item.increment)
        );
      }
    });
    const filteredChanges = changes.filter(change => change !== null);

    if (filteredChanges.length > 0) {
      instance.setDataAtCell(filteredChanges, 'prefill');
    }
  });
};

export const getDataInHandsonTable = (instance, row, column) => instance.getDataAtCell(row, column);

export const moveToNextRowAndOffset = (instance, currentRow, startOffset) => {
  instance.selectCell(currentRow + 1, startOffset);
  return {
    row: 0,
    col: 0
  };
};

export const moveToNextCol = () => ({
  row: 0,
  col: 1
});


export const filterNonChanges = changes => changes
  .filter(([,, oldValue, newValue]) => oldValue !== newValue);

export const filterEmptyChanges = changes => changes
  .filter(([row, column, oldValue, newValue]) =>
    !(isEmptyOrNull(oldValue) && isEmptyOrNull(newValue)));

export const toStringReplacer = (key, value) => value && value.toString();

export const downloadTable = (hotInstance, filename) => {
  const exporter = hotInstance.getPlugin('exportFile');
  exporter.downloadFile('csv', {
    filename,
    exportHiddenRows: true,
    exportHiddenColumns: true,
    columnHeaders: true
  });
};

export const fillEmptyRows = (data, rowCount, columnCount) => {
  const result = [];
  for (let i = 0; i < rowCount; i += 1) {
    result.push(data[i] || [...Array(columnCount).fill([])]);
  }
  return result;
};

export const countFilledRows = (data) => data.filter(cols => {
  let arr = cols;
  if (typeof (cols) === 'object') {
    arr = Object.values(cols);
  }
  return arr && Array.isArray(arr) && arr.join('') !== '';
}).length;

export const yesNoValidator = (value, callback) => {
  if (value === 'y' || value === 'n' || !value) {
    return callback(true);
  }
  return callback(false);
};

export const sexValidator = (value, callback) => {
  if (value === 'm' || value === 'f' || !value) {
    return callback(true);
  }
  return callback(false);
};

export const causeValidator = (value, callback) => {
  const allowedValues = ['1', '2', '3', 1, 2, 3];
  if (allowedValues.includes(value)) {
    return callback(true);
  }
  return callback(false);
};

export const locationValidator = (value, callback) => {
  const allowedValues = ['1', '2', '3', '4', 1, 2, 3, 4];
  if (allowedValues.includes(value)) {
    return callback(true);
  }
  return callback(false);
};

export const measureValidator = (value, callback) => {
  if (value === 'h' || value === 'l' || !value) {
    return callback(true);
  }
  return callback(false);
};

export const getDuplicatesByColumns = (data, columns, offset = 0) => {
  const columnValues = data
    .filter(cols => cols && Array.isArray(cols) && cols.join('') !== '')
    .map((row, index) => {
      const values = columns.map(colIndex => row[colIndex + offset]);
      return {
        row: index,
        values
      };
    });
  const grouped = groupBy(columnValues, 'values');
  return omitBy(grouped, (value, key) => value.length === 1);
};


export const getDifferenceInSourceAndFilteredRows = (sourceRows, filteredRows, selected) => {
  const include = Object.values(selected).flat().map((row, index) => row.row);
  const filtered = filteredRows.map((_, index) => index);
  const source = sourceRows.map((_, index) => index);

  return difference(source, difference(filtered, include));
};

export const getDifferenceInRows = (rows, selected) => {
  const include = Object.values(selected).flat().map(row => row.row);
  const all = rows.map((row, index) => index);
  return difference(all, include);
};

export const getColumnIndexes = (selectedColumns, columns) => selectedColumns.map(column => columns.indexOf(column));

const getMortalityDataChanges = (rowData, headerConfigs, headerKeys, changes) => {
  const data = { changes };
  if (!headerConfigs) {
    return null;
  }
  headerConfigs.forEach((header, index) => {
    const { key } = header;
    const change = changes.find((item) => item[1] === index);

    if (key !== 'id') {
      if (change && !header?.config?.readOnly) {
        const [,,, curValue] = change;
        data[key] = curValue;
      } else {
        data[key] = rowData[index];
      }
    } else {
      data[key] = rowData[index];
    }
  });
  return data;
};

const getAnthroDataChanges = (rowData, headerConfigs, changes) => {
  const data = { changes };
  if (!headerConfigs) {
    return null;
  }
  headerConfigs.forEach((header, index) => {
    const { key } = header;
    const change = changes.find((item) => item[1] === index);
    if (key !== 'normalized_submission_id') {
      if (change && !header?.config?.readOnly) {
        // eslint-disable-next-line prefer-destructuring
        data[key] = change[3];
      } else if (!header?.config?.readOnly) {
        data[key] = rowData[index];
      }
    } else {
      data[key] = rowData[index];
    }
  });
  return data;
};

export const shapeMortalityUpdateRequestData = (changes, hotInstance, headerConfigs, headerKeys) => {
  const groupedByRowChanges = groupBy(changes, (item) => item[0]);
  return Object.keys(groupedByRowChanges)
    .map((row) =>
      getMortalityDataChanges(
        hotInstance.getDataAtRow(row), headerConfigs, headerKeys, groupedByRowChanges[row]
      ));
};

export const shapeAnthroDateChanges = (changes, hotInstance, headerConfigs, monthIndex) => {
  const groupedByRowChanges = groupBy(changes, (item) => item[0]);

  const filteredResults = {};
  for (const [key, groupedChanges] of Object.entries(groupedByRowChanges)) {
    if (groupedChanges.every(change => change[1] !== monthIndex)) {
      filteredResults[key] = groupedChanges;
    }
  }

  return Object.keys(groupedByRowChanges)
    .map((row) => getAnthroDataChanges(hotInstance.getDataAtRow(row), headerConfigs, groupedByRowChanges[row]))
    .map(objectWithDate => {
      const rowIndex = objectWithDate.changes[0][0];
      const newAge = calculateNewAge(objectWithDate.survdate, objectWithDate.birthdate);
      if (newAge !== null) {
        return [rowIndex, monthIndex, newAge];
      }
      return [];
    })
    .filter(dateChanges => dateChanges.length > 0);
};

export const shapeAnthroUpdateRequestData = (changes, hotInstance, headerConfigs) => {
  const groupedByRowChanges = groupBy(changes, (item) => item[0]);
  return Object.keys(groupedByRowChanges).map((row) => getAnthroDataChanges(hotInstance.getDataAtRow(row), headerConfigs, groupedByRowChanges[row]));
};

export const shapeEnaFileUpload = (columnHeaderKeys, rowsToAdd, source) => {
  /**
   * result: [{
   *     changes: [], // Changes is expected to be empty for new values
   *     date: Date
   *     cluster: int
   *     team: int
   *     hh: int
   *     age: int
   *     sex: string
   *     joined: string
   *     left: string
   *     died: string
   *     source: string
   * }]
   */
  const result = [];
  const rowProperties = [
    { key: 'date', smartValue: 'survdate' },
    { key: 'cluster', smartValue: 'cluster' },
    { key: 'team', smartValue: 'team' },
    { key: 'hh', smartValue: 'hh' }
  ];
  const enaPersonProperties = [
    { key: 'sex', smartValue: 'sex' },
    { key: 'age', smartValue: 'age' },
    { key: 'left', smartValue: 'left' },
    { key: 'join', smartValue: 'joined' },
    { key: 'born', smartValue: 'born' },
    { key: 'died', smartValue: 'died' },
    { key: 'cause', smartValue: 'cause' },
    { key: 'location', smartValue: 'location' }];
  rowsToAdd.forEach((row) => {
    const personObject = {};

    for (const property of rowProperties) {
      const propIndex = columnHeaderKeys.indexOf(property.key);
      if (propIndex > -1) {
        personObject[property.smartValue] = property.key === 'date' ? formatSmartDates(row[propIndex]) : row[propIndex];
      }
    }
    let count = 1;
    let hasMorePeople = true;
    while (hasMorePeople) {
      const subPersonObject = Object.assign({
        changes: [],
        source
      }, personObject);
      for (const property of enaPersonProperties) {
        const subPropIndex = `p${count}_${property.key}`;

        const propIndex = columnHeaderKeys.indexOf(subPropIndex);
        if (propIndex > -1) {
          subPersonObject[property.smartValue] = row[propIndex];
        }
      }

      if ((!subPersonObject.sex) && (!subPersonObject.age)) {
        hasMorePeople = false;
      } else {
        result.push(subPersonObject);
      }
      count += 1;
    }
  });
  return result;
};

export const shapeUploadRequestData = (coreHeaderConfigs, headerRow, rowsToAdd, source) => {
  const keyToHeadersMap = {};
  const dateFormatMap = {};

  headerRow.forEach((item, headerIndex) => {
    const coreHeaderIndex = coreHeaderConfigs
      .findIndex(
        config => config.key === item ||
        (config.aliasKeys && config.aliasKeys.includes(item))
      );

    if (coreHeaderIndex > -1) {
      const headerConfig = coreHeaderConfigs[coreHeaderIndex];
      if (headerConfig.config.dateFormat) {
        dateFormatMap[headerConfig.key] = headerConfig.config.dateFormat;
      }
      keyToHeadersMap[headerConfig.key] = headerIndex;
    }
  });
  const result = rowsToAdd.map(rowToCreate => {
    const data = { changes: [] };
    for (const [key, value] of Object.entries(keyToHeadersMap)) {
      if (key in keyToHeadersMap) {
        if (key in dateFormatMap && rowToCreate[value]) {
          data[key] = formatSmartDates(rowToCreate[value], dateFormatMap[key]);
        } else {
          data[key] = rowToCreate[value];
        }
      } else {
        data[key] = null;
      }
    }
    data.source = source;

    if (data.survdate && data.birthdate && !data.months) {
      data.months = calculateNewAge(data.survdate, data.birthdate);
    }
    return data;
  }).filter(data => data.hh != null || data.cluster != null || data.team != null);

  return result;
};

export const chunkDataUploadArray = (arr, splitForEna = false) => {
  const results = [];
  const split = splitForEna ? ENA_CHUNK_SIZE : CHUNK_SIZE;

  const groupedByRowChanges = groupBy(arr, (item) => item[0]);
  if (splitForEna) {
    while (arr.length) {
      results.push(arr.splice(0, split));
    }
  } else {
    let chunkedArray = [];
    for (const [_, value] of Object.entries(groupedByRowChanges)) {
      chunkedArray = chunkedArray.concat(value);
      if (chunkedArray.length >= split) {
        results.push(chunkedArray);
        chunkedArray = [];
      }
    }
    if (chunkedArray.length > 0) {
      results.push(chunkedArray);
    }
  }
  return results;
};
