// eslint-disable-next-line import/prefer-default-export
export const downloadBase64 = async (data, filename) => {
  const res = await fetch(data);
  const blob = await res.blob();
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
};

export const downloadBase64FromBlob = async (data, filename) => {
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
};

export const shouldUseSupervisorTEM = (result) => {
  if (result?.bias_relative_to?.toUpperCase()) {
    const outcome = result.bias_relative_to.toUpperCase();
    return outcome.includes('SUPERVISOR');
  }
  return false;
};
