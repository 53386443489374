<template>
  <a-input
    :placeholder="placeholder"
    :value="value"
    :disabled="isSupervisor"
    @change="search">
      <a-tooltip v-if="hasSearched && isNameAvailable" slot="suffix" title="This name is available">
        <a-icon
          v-if="hasSearched && isNameAvailable"
          type="check-square" :style="{ color: '#59e059', fontSize: '24px' }"/>
      </a-tooltip>
      <a-tooltip v-if="hasSearched && !isNameAvailable"
        slot="suffix" :title="$t('components.toolTips.nameTaken')">
        <a-icon type="close-square" :style="{ color: 'red', fontSize: '24px' }"/>
      </a-tooltip>
  </a-input>
</template>

<script>
import Vue from 'vue';
import debounce from 'lodash/debounce';
import { mapState } from 'vuex';
import { configForPossibleBackendRequest } from '../../../../../util/request';

export default {
  name: 'GeographicAreaNameInput',
  props: {
    initialName: {
      type: String,
      default: undefined
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isNameAvailable: false,
      hasSearched: false
    };
  },
  computed: {
    ...mapState({
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    })
  },
  methods: {
    // eslint-disable-next-line func-names
    search: debounce(async function(event) {
      this.$emit('input', event.target.value);
      if (this.initialName && this.initialName === event.target.value) {
        this.hasSearched = false;
        this.isNameAvailable = true;
        return;
      }
      try {
        if (!event.target.value) {
          this.hasSearched = false;
          return;
        }
        const baseConfig = { method: 'POST', url: '/checkGeographicName', data: { name: event.target.value } };
        const token = this.$store.getters.loggedIn
          ? this.$store.state.request.data.session.token
          : null;
        const axiosConfig = configForPossibleBackendRequest(baseConfig, token);
        const response = await Vue.prototype.$http.request(axiosConfig);
        this.hasSearched = true;
        this.isNameAvailable = !!response?.data?.available;
      } catch (error) {
        // do nothing
      }
    }, 500)
  }
};
</script>

<style lang="scss" scoped>
</style>
