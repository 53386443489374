<template>
  <div v-if="standardizationResult" class="flex align-center">
    <div class="outcome-result" :class="standardizationResult.tem">
      {{ standardizationResult.tem && standardizationResult.tem.toLowerCase() }}
      <span class="outcome">{{ standardizationResult.temNumber }}</span>
    </div>
    <div class="ml-4 mr-4">/</div>
    <div class="outcome-result" :class="standardizationResult.bias">
      {{ standardizationResult.bias && standardizationResult.bias.toLowerCase() }}
      <span class="outcome">{{ standardizationResult.biasNumber }}</span>
    </div>
  </div>
  <div v-else class="opacity02 txt-normal">
    {{ $t('components.description.resultsNotReceivedYet') }}
  </div>
</template>

<script>

export default {
  name: 'StandardizationOutcome',
  props: {
    enumerator: {
      type: Object,
      required: true
    },
    property: {
      type: String,
      required: true
    }
  },
  computed: {
    standardizationResult() {
      const result = this.enumerator.tembias;
      if (result.length === 0) return null;

      const height = result.find(r => r.metric.toUpperCase() === 'HEIGHT');
      const weight = result.find(r => r.metric.toUpperCase() === 'WEIGHT');
      const muac = result.find(r => r.metric.toUpperCase() === 'MUAC');
      const results = {
        height: this.getMetric(height),
        weight: this.getMetric(weight),
        muac: this.getMetric(muac)
      };

      return results[this.property];
    }
  },
  methods: {
    getMetric(result) {
      const requiredKeys = ['outcome_bias', 'outcome_tem'];
      const keys = Object.keys(result);
      if (!requiredKeys.every(key => keys.includes(key))) {
        return undefined;
      }

      return {
        bias: result?.outcome_bias?.replaceAll('Bias', '')?.trim()?.toUpperCase(),
        tem: result?.outcome_tem?.replaceAll('TEM', '')?.trim()?.toUpperCase(),
        biasNumber: result?.bias,
        temNumber: result?.tem
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.outcome-result {
  text-transform: capitalize;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 3px;
  padding-bottom: 3px;
  position: relative;
  font-weight: normal;

  &.POOR {
    background: #ecc182;
    color: #a55b22;
  }

  &.REJECT {
    background: #ffe4e4;
    color: #be0600;
  }

  &:hover {
    color: transparent;
    .outcome {
      display: flex;
      justify-items: center;
      align-items: center;
      color: black;
      text-align: center;
    }
  }

  .outcome {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
</style>
