/* eslint-disable import/prefer-default-export */
export const handsontableFilterDropdown = (vueInstance) => ({
  items: {
    sort_ascended: {
      name() {
        return `<img width="20px" src="/Ascending.svg" class="mr-8 pb-4"/> ${vueInstance.$t('components.description.sortAscending')}`;
      },
      callback(key, [selection]) {
        const columnSortPlugin = this.getPlugin('columnSorting');
        columnSortPlugin.sort({ column: selection.start.col, sortOrder: 'asc' });
      }
    },
    sort_descended: {
      name() {
        return `<img width="20px" src="/Descending.svg" class="mr-8 pb-4"> ${vueInstance.$t('components.description.sortDescending')}`;
      },
      callback(key, [selection]) {
        const columnSortPlugin = this.getPlugin('columnSorting');
        columnSortPlugin.sort({ column: selection.start.col, sortOrder: 'desc' });
      }
    },
    clear_filters: {
      name() {
        return `<img width="20px" src="/Circle-X.svg" class="mr-8 mb-4"> ${vueInstance.$t('components.description.clearFiltersFromColumn')}`;
      },
      callback(key, [selection]) {
        const filtersPlugin = this.getPlugin('filters');
        filtersPlugin.clearConditions(selection.start.col);
        filtersPlugin.filter();
        vueInstance.$root.$emit('clearDataEntryFilter');
      }
    },
    '---------': {},
    filter_by_condition: {},
    filter_operators: {},
    filter_by_condition2: {},
    filter_by_value: {},
    filter_action_bar: {}
  }
});

export const handsontableRowContextMenu = (vueInstance) => ({
  items: {
    row_above: {
      disabled() {
        return vueInstance.isSupervisor;
      },
      name() {
        return `<img width="20px" src="/icon-add-row-above.svg" class="mr-8 pb-4"/>${vueInstance.$t('components.description.insertRowAbove')}`;
      },
      callback(key, selection, clickEvent) {
        if (vueInstance.isSupervisor) {
          return;
        }
        const endRow = selection[0].end.row;
        this.alter('insert_row', endRow);
      }
    },
    row_below: {
      disabled() {
        return vueInstance.isSupervisor;
      },
      name() {
        return `<img width="20px" src="/icon-add-row-below.svg" class="mr-8 pb-4"/>${vueInstance.$t('components.description.insertRowBelow')}`;
      },
      callback(key, selection, clickEvent) {
        if (vueInstance.isSupervisor) {
          return;
        }
        const endRow = selection[0].end.row;
        this.alter('insert_row', endRow + 1);
      }
    },
    clear_row: {
      disabled() {
        return vueInstance.isSupervisor;
      },
      name() {
        return `<img width="20px" src="/icon-circle-x.svg" class="mr-8 pb-4"/>${vueInstance.$t('components.description.clearRow')}`;
      },
      callback(key, selection, clickEvent) {
        if (vueInstance.isSupervisor) {
          return;
        }
        const colHeaders = this.getColHeader();
        const changes = [];
        selection.forEach(range => {
          const startRow = range.start.row;
          const endRow = range.end.row;
          const rowCount = (endRow - startRow) + 1;
          for (let i = 0; i < rowCount; i += 1) {
            const currentRow = startRow + i;
            if (!this.isEmptyRow(currentRow)) {
              changes.push(...colHeaders.map((_, indx) => [currentRow, indx, null]));
            }
          }
        });
        if (changes.length) {
          this.setDataAtCell(changes, 'clear_row');
        }
      }
    },
    delete_row: {
      disabled() {
        return vueInstance.isSupervisor;
      },
      name() {
        return `<img width="20px" src="/icon-trash.svg" class="mr-8 pb-4"/>${vueInstance.$t('components.description.deleteRow')}`;
      },
      callback(key, selection, clickEvent) {
        if (vueInstance.isSupervisor) {
          return;
        }
        let changes = [];
        let removeChanges = [];
        for (let i = 0; i < selection.length; i += 1) {
          const range = selection[i];
          const startRow = range.start.row;
          const endRow = range.end.row;
          const rowCount = (endRow - startRow) + 1;
          for (let j = 0; j < rowCount; j += 1) {
            const currentRow = startRow + j;
            const data = this.getDataAtRow(currentRow);
            if (data[0]) {
              changes = [...changes, [currentRow, 0, null, false]];
            } else {
              removeChanges = [...removeChanges, [currentRow, 1]];
            }
          }
        }
        this.alter('remove_row', removeChanges);
        const adjustedChanges = changes.map(change => {
          const below = removeChanges.filter(removeChange => removeChange[0] < change[0]);
          return [change[0] - below.length, change[1], change[2], change[3]];
        });
        this.setDataAtCell(adjustedChanges);
      }
    }
  }
});
