import XLSX from 'xlsx';

export const getAnthropometrySettingsSheet = (settings) => {
  const { clothingWeight, muac_lwr: muacLwr, muac_upr: muacUpr, restricted } = settings;
  const anthroSettings = [
    ['clothingWeight', 'muac_lwr', 'muac_upr',
      ...restricted.map((item) => (`restricted_${item.queryKey} (${item.name})`)),
      'zScoreExclusion', 'zScoreValues_smart', 'zScoreValues_who'],
    [clothingWeight, muacLwr, muacUpr,
      ...restricted.map((item) => (item.selected ? `${item.start} to ${item.end}` : 'none')),
      settings.zScoreExclusion, JSON.stringify(settings.zScoreValues.smart, null, 2), JSON.stringify(settings.zScoreValues.who, null, 2)]
  ];
  return XLSX.utils.aoa_to_sheet(anthroSettings);
};

export const getMortalitySettingsSheet = (settings) => {
  const { locationOfDeath, causeOfDeath } = settings;
  const mortalitySettings = [
    ['locationOfDeath', 'causeOfDeath'],
    [JSON.stringify(locationOfDeath, null, 2), JSON.stringify(causeOfDeath, null, 2)]
  ];
  return XLSX.utils.aoa_to_sheet(mortalitySettings);
};
