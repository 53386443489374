import toolTips from './Tooltips.json';
import description from './Description.json';
import labels from './Labels.json';
import titles from './Titles.json';
import notifications from './Notifications.json';
import dropdown from './Dropdown.json';

const components = {
  toolTips,
  description,
  labels,
  titles,
  notifications,
  dropdown
};

export default components;
