<template>
  <a-modal
    width="620px"
    class="success-modal"
    :dialog-style="{ display: 'none' }"
    :footer="null"
    :visible="true"
    :closable="false"
    @onCancel="onExit">
    <div class="finish-header">
      <div class="circle-outline">
        <a-icon type="check-circle" theme="filled"/>
      </div>
    </div>
    <slot></slot>
  </a-modal>
</template>

<script>
import { noop } from '../util/util';

export default {
  name: 'SuccessModal',
  props: {
    onExit: {
      type: Function,
      default: noop
    },
    visible: {
      type: Boolean,
      default: true
    },
    closable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
  }
};
</script>

<style lang="scss">
.success-modal {
  text-align: center;
  .ant-modal-body {
    padding: 0;
  }
}

.circle-outline {
  background: #66c300;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  position: absolute;
  bottom: -42px;
  left: calc(50% - 42px);
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    color: white;
    font-size: 70px;
  }
}

.finish-header {
  position: relative;
  height: 95px;
  width: 100%;
  background: #66c300;
  margin-bottom: 80px;
}

.success-button {
  border: 2px solid #529e00;
  color: #529e00;
  border-radius: 1px;

  &:hover {
    border: 2px solid #529e00;
    color: #529e00;
    opacity: 0.5;
  }
}

.success-button-filled {
  border: 2px solid #529e00;
  background: #529e00;
  color: white;
  border-radius: 1px;

  &:hover {
    border: 2px solid #529e00;
    background: #529e00;
    color: white;
    opacity: 0.5;
  }
}
</style>
